var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto" } },
    [
      _c(
        "div",
        { staticClass: "relative mx-auto" },
        [
          _c(
            "div",
            {
              staticClass: "absolute w-full h-full",
              staticStyle: { "z-index": "-1" }
            },
            [
              _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading || !_vm.isReady,
                        expression: "isLoading || !isReady"
                      }
                    ],
                    staticClass:
                      "loader-container flex justify-center items-center pt-16 pb-24"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "loader-animation inline-block" },
                      [_c("span")]
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition-group",
            { attrs: { name: "fadeIn", mode: "in-out" } },
            [
              Object.keys(_vm.items).length > 0
                ? [
                    !!_vm.items["people"] && _vm.items["people"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 0,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("People results")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap mb-8 md:-mx-4 lg:-mx-3"
                              },
                              _vm._l(_vm.items["people"], function(i) {
                                return _c("search-page-person-card", {
                                  key: i.id,
                                  attrs: {
                                    item: i,
                                    "is-loading": _vm.isLoading
                                  }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "move inline-flex items-end",
                                attrs: {
                                  href: !!_vm.searchQuery
                                    ? _vm.peopleLink + "?q=" + _vm.searchQuery
                                    : _vm.peopleLink
                                }
                              },
                              [
                                _c("span", { staticClass: "text-4xl mr-6" }, [
                                  _vm._v(
                                    'View all people with "' +
                                      _vm._s(_vm.searchQuery) +
                                      '"'
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "64px",
                                        height: "31px",
                                        viewBox: "0 0 64 31"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(-1277.000000, -226.000000)"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(1004.000000, 92.000000)"
                                              }
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(0.000000, 114.000000)"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        transform:
                                                          "translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M23,44 L45,44",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M44,23 L44,45",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("polygon", {
                                                        staticClass:
                                                          "fill-current",
                                                        attrs: {
                                                          transform:
                                                            "translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) ",
                                                          points:
                                                            "-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.items["openPositions"] &&
                    _vm.items["openPositions"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 1,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("Open positions")]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "mb-8" },
                              _vm._l(_vm.items["openPositions"], function(i) {
                                return _c(
                                  "li",
                                  { key: i.id, staticClass: "mb-6" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-4xl border-b border-transparent hover:border-black transition",
                                        attrs: {
                                          href: i.jobLink,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(i.title))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.items["cases"] && _vm.items["cases"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 2,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("Highlights results")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap mb-12 lg:-mx-4" },
                              _vm._l(_vm.items["cases"], function(i) {
                                return _c("search-page-article-card", {
                                  key: i.id,
                                  attrs: {
                                    item: i,
                                    "is-loading": _vm.isLoading
                                  }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "move inline-flex items-end",
                                attrs: {
                                  href: !!_vm.searchQuery
                                    ? _vm.casesLink + "?q=" + _vm.searchQuery
                                    : _vm.casesLink
                                }
                              },
                              [
                                _c("span", { staticClass: "text-4xl mr-6" }, [
                                  _vm._v(
                                    'View all highlights with "' +
                                      _vm._s(_vm.searchQuery) +
                                      '"'
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "64px",
                                        height: "31px",
                                        viewBox: "0 0 64 31"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(-1277.000000, -226.000000)"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(1004.000000, 92.000000)"
                                              }
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(0.000000, 114.000000)"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        transform:
                                                          "translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M23,44 L45,44",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M44,23 L44,45",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("polygon", {
                                                        staticClass:
                                                          "fill-current",
                                                        attrs: {
                                                          transform:
                                                            "translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) ",
                                                          points:
                                                            "-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.items["news"] && _vm.items["news"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 3,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("News results")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap mb-12 lg:-mx-4" },
                              _vm._l(_vm.items["news"], function(i) {
                                return _c("search-page-article-card", {
                                  key: i.id,
                                  attrs: {
                                    item: i,
                                    "is-loading": _vm.isLoading
                                  }
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "move inline-flex items-end",
                                attrs: {
                                  href: !!_vm.searchQuery
                                    ? _vm.newsLink + "?q=" + _vm.searchQuery
                                    : _vm.newsLink
                                }
                              },
                              [
                                _c("span", { staticClass: "text-4xl mr-6" }, [
                                  _vm._v(
                                    'View all news with "' +
                                      _vm._s(_vm.searchQuery) +
                                      '"'
                                  )
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "64px",
                                        height: "31px",
                                        viewBox: "0 0 64 31"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            transform:
                                              "translate(-1277.000000, -226.000000)"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                transform:
                                                  "translate(1004.000000, 92.000000)"
                                              }
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    transform:
                                                      "translate(0.000000, 114.000000)"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        transform:
                                                          "translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M23,44 L45,44",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        staticClass:
                                                          "stroke-current",
                                                        attrs: {
                                                          d: "M44,23 L44,45",
                                                          "stroke-width": "2"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("polygon", {
                                                        staticClass:
                                                          "fill-current",
                                                        attrs: {
                                                          transform:
                                                            "translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) ",
                                                          points:
                                                            "-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.items["events"] && _vm.items["events"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 3,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("Event results")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "flex flex-wrap mb-12 lg:-mx-4" },
                              _vm._l(_vm.items["events"], function(i) {
                                return _c("search-page-article-card", {
                                  key: i.id,
                                  attrs: {
                                    item: i,
                                    "is-loading": _vm.isLoading
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !!_vm.items["pages"] && _vm.items["pages"].length > 0
                      ? _c(
                          "section",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isLoading,
                                expression: "!isLoading"
                              }
                            ],
                            key: 4,
                            staticClass:
                              "px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-3 border-b border-black mb-12"
                              },
                              [_vm._v("Page results")]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "mb-8" },
                              _vm._l(_vm.items["pages"], function(i) {
                                return _c(
                                  "li",
                                  { key: i.id, staticClass: "mb-6" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-4xl border-b border-transparent hover:border-black transition",
                                        attrs: { href: i.url }
                                      },
                                      [_vm._v(_vm._s(i.title))]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fadeIn", mode: "in-out" } },
            [
              !_vm.isLoading && _vm.items.length === 0
                ? [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "heading-4 uppercase mx-auto py-8 text-center"
                      },
                      [_vm._v("No results found")]
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }