class AccordionToggle {
	constructor() {
		const accordionWrappers = document.querySelectorAll('.accordion-outer');

		for (var i = 0; i < accordionWrappers.length; i++) {
			const accordionWrapper = accordionWrappers[i];
			this.initAccordion(accordionWrapper);
		}
	}

	initAccordion(accordionWrapper) {
		const accordions = accordionWrapper.querySelectorAll('[data-toggle-container]');

		for (var i = 0; i < accordions.length; i++) {
			const accordion = accordions[i];
			const index = i;
			accordion.addEventListener('click', (event) => this.handleClick(event, index, accordionWrapper));
		}
	}

	handleClick(event, index, accordionWrapper) {

		if (!event.target.closest('[data-toggle]')) return;

		const accordions = accordionWrapper.querySelectorAll('[data-toggle-container]');

		for (var i = 0; i < accordions.length; i++) {
			const accordion = accordions[i];

			if (i == index) {
				this.toggleAccordion(accordion);
			} else {
				this.hideAccordion(accordion);
			}
		}
	}

	toggleAccordion(accordion) {
		const toggledElements = accordion.querySelectorAll('[data-toggle-class]');

		for (var i = 0; i < toggledElements.length; i++) {
			const element = toggledElements[i];
			const toggleClass = element.getAttribute('data-toggle-class');

			element.classList.toggle(toggleClass);
		}
	}

	hideAccordion(accordion) {
		const toggledElements = accordion.querySelectorAll('[data-toggle-class]');

		for (var i = 0; i < toggledElements.length; i++) {
			const element = toggledElements[i];
			const toggleClass = element.getAttribute('data-toggle-class');

			element.classList.add(toggleClass);
		}
	}
}

new AccordionToggle();