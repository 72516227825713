var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        staticClass:
          " md:px-3 xl:px-4 w-full md:w-1/2 lg:w-1/4 mb-6 md:mb-8 overflow-hidden people-card-outer text-base"
      },
      [
        _c(
          "div",
          { staticClass: "aspect-ratio-70 relative mb-6 bg-gray-image" },
          [
            _c("div", {
              staticClass:
                "cardLoader__image absolute inset-0 w-full h-full block object-cover"
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "line-medium mb-2" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "line-small mb-4" }, [_vm._v("Title")]),
        _vm._v(" "),
        _c("h3", { staticClass: "line-medium-large mb-2 ellipsis" }, [
          _vm._v("Email")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line-medium mb-2" }, [_vm._v("Phone")])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }