<template>
  <div
    class="relative lg:px-2 xl:px-20 pb-12 max-w-screen m-auto"
    :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }"
  >
    <div class="flex flex-col xl:flex-row xl:justify-between mb-16">
      <div class="hidden md:block -mx-3 overflow-x-scroll whitespace-no-wrap text-xl no-scroll">
        <div
          v-for="(row) in alphabets"
          :key="row"
          @click="chosenLetter === row ? chosenLetter = '' : chosenLetter = row"
          class="group hidden px-3 md:inline-block transition cursor-pointer"
          :class="{ 'pointer-events-none opacity-25': !letterFilters.includes(row) }"
        >
          <span
            class="inline-block border-b border-transparent group-hover:border-black"
            :class="{ 'border-black': chosenLetter === row }"
          >{{ row }}</span>
        </div>
      </div>
      <div
        v-show="chosenLetter || selectedCategories.length > 0"
        class="block border-b hover:border-transparent border-black cursor-pointer text-base self-start md:self-end mt-4 xl:mt-0"
        @click="resetFilters()"
      >Clear filters</div>
    </div>
    <div class="justify-end items-baseline hidden sm:flex">
      <div class="flex items-center">
        <span class="hidden md:inline-block ml-8 text-base">Show as</span>
        <div class="ml-5">
          <input
            type="radio"
            name="people-radio"
            id="image-radio"
            value="image"
            @click="openImageView()"
            checked
          />
          <label class="radio-btn" for="image-radio">Image</label>
        </div>
        <div class="ml-5">
          <input
            type="radio"
            name="people-radio"
            id="list-radio"
            value="list"
            @click="openListView()"
          />
          <label class="radio-btn" for="list-radio">List</label>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        class="absolute w-full lg:pr-4 xl:pr-40 -my-8 md:-my-10 pt-50"
        style="z-index: -1"
        v-show="isLoading || !isReady"
      >
        <div class="loader-container flex justify-center items-center pt-16 pb-24">
          <div class="loader-animation inline-block">
            <span></span>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <template v-if="propItems">
        <!-- removing people-list class reveals img view-->
        <div class="people-view -my-8 md:-my-10" v-show="!isLoading">
          <template v-for="(position) in positionsOrder">
            <template v-for="(item, key) in _.pick(propItems, [position])">
              <div class="lg:mb-10" :key="key" v-if="key !== 'Management Team' || (key === 'Management Team' && selectedCategories.length === 0) || (key === 'Management Team' && selectedCategories.map((val)=>{return val.id}).includes(7006))">
                <div class="mb-6 md:mb-8">
                  <span class="text-4xl md:text-6xl">{{ key }}</span>
                </div>
                <div class="list-container flex flex-wrap sm:-mx-3 xl:-mx-4">
                  <category-search-item
                    v-for="(item, key) in item"
                    :key="key"
                    :item="item"
                    :is-loading="isLoading"
                    :section-type="sectionType"
                  ></category-search-item>
                </div>
              </div>
            </template>
          </template>
        </div>
      </template>
    </transition>
    <transition name="fade" mode="in-out">
      <template v-if="!isLoading && _.size(propItems) === 0">
        <h4
          class="font-euclid-bold mb-6 mt-24 leading-slight text-3xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center"
        >No matching people found</h4>
      </template>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue'

import CategorySearchItem from './CategorySearchItem.vue'
import CardLoadingIndicator from './CardLoadingIndicator.vue'
import runFallback from '../js/helpers/objectfitFallback'

export default {
  name: 'CategorySearchList',
  props: {
    sectionType: {
      type: Array,
      default: [],
    },
    positionsOrder: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isReady: {
      type: Boolean,
      default: false,
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: ''
    }
  },
  components: {
    CategorySearchItem,
    CardLoadingIndicator,
  },
  watch: {
    items: function(val, oldVal) {
      const validLetters = _.uniq(
        _.map(val, item => {
          return item.lastName.substring(0, 1).toLowerCase()
        }),
      )
      if (!validLetters.includes(this.chosenLetter)) {
        this.chosenLetter = ''
      }
      this.letterFilters = validLetters.sort()
      this.filterItems(val, this.chosenLetter)
    },
    chosenLetter: function(val, oldVal) {
      this.filterItems(this.items, val)
    },
  },
  data() {
    return {
      alphabets: [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
        'å',
        'ä',
        'ö',
      ],
      propItems: {},
      letterFilters: [],
      chosenLetter: '',
    }
  },
  computed: {
    _() {
      return _
    },
  },
  methods: {
    resetFilters: function() {
      this.$emit('update:selected-categories', [])
      this.$emit('update:query', '')
      this.$emit('update:start-date', {})
      this.chosenLetter = ''
    },
    listMinHeight: function() {
      let rowHeight = 500
      let rowAmount = 2
      if (window.innerWidth < 480) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 340
        rowAmount = this.sectionType[0] === 'events' ? 3 : 4
      } else if (window.innerWidth < 780) {
        rowHeight = this.sectionType[0] === 'events' ? 280 : 240
        rowAmount = this.sectionType[0] === 'events' ? 3 : 8 / 2
      }
      return rowAmount * rowHeight + 'px'
    },
    openListView() {
      const peopleViews = document.querySelectorAll('.people-view')
      peopleViews.forEach(element => {
        element.classList.add('people-list')
      })
    },
    openImageView() {
      const peopleViews = document.querySelectorAll('.people-view')
      peopleViews.forEach(element => {
        element.classList.remove('people-list')
      })
    },
    extractCoreLastName(lastName) {
        const nobiliaryParticles = ["von", "de", "la", "del", "di", "du", "van", "der"];
        let parts = lastName.split(' ');
        parts = parts.filter(part => !nobiliaryParticles.includes(part));
        return parts.join(' ');
    },   
    filterItems(newItems, newLetter) {
      const sortableItems = _.map(newItems, o => { return { ...o, lastName: this.extractCoreLastName(o.lastName).toLowerCase(), firstName: o.firstName.toLowerCase() } })
      const sortedItems = (!!this.query) ? sortableItems : _.sortBy(sortableItems, ['lastName', 'firstName']);
      let modifiedVal = sortedItems
      let filteredItems
      const selectedPersonnelCategory = _.find(this.selectedCategories, o => {
        return o.parentCategory.id === 1
      })
      if (selectedPersonnelCategory) {
        const groupedChildren = {}
        if (modifiedVal.length > 0) {
          modifiedVal = {
            [selectedPersonnelCategory.title]: sortedItems,
          }
          if (newLetter) {
            filteredItems = _.filter(
              modifiedVal[selectedPersonnelCategory.title],
              o => o.lastName.substring(0, 1).toLowerCase() === newLetter,
            )
            if (filteredItems.length > 0) {
              if (selectedPersonnelCategory.children) {
                _.forEach(modifiedVal[selectedPersonnelCategory.title], m => {
                  if (m.lastName.substring(0, 1).toLowerCase() === newLetter) {
                    m.position.forEach(element => {
                      if (_.get(element, 'parent.title') === selectedPersonnelCategory.title) {
                        if (_.get(groupedChildren, [element.title])) {
                          groupedChildren[
                            element.title
                          ] = _.concat(groupedChildren[element.title], [m])
                        } else {
                          groupedChildren[element.title] = [m]
                        }
                      }
                    })
                  }
                })
                this.propItems = _.omit(
                  groupedChildren,
                  selectedPersonnelCategory.title,
                )
              } else {
                this.propItems = {
                  [selectedPersonnelCategory.title]: filteredItems,
                }
              }
            } else {
              this.propItems = {}
            }
          } else {

            if (selectedPersonnelCategory.children) {
              _.forEach(modifiedVal[selectedPersonnelCategory.title], m => {
                m.position.forEach(element => {
                  if (_.get(element, 'parent.title') === selectedPersonnelCategory.title) {
                    if (_.get(groupedChildren, [element.title])) {
                      groupedChildren[element.title] = _.concat(
                        groupedChildren[element.title],
                        [m],
                      )
                    } else {
                      groupedChildren[element.title] = [m]
                    }
                  }
                })
              })
              this.propItems = _.omit(
                groupedChildren,
                selectedPersonnelCategory.title,
              )
            } else {
              this.propItems = modifiedVal
            }
          }
        } else {
          this.propItems = {}
        }
      } else {
        const groupedItems = {}
        _.forEach(sortedItems, o => {
          if (newLetter) {
            if (o.lastName.substring(0, 1).toLowerCase() === newLetter) {
              o.position.forEach(element => {
                if (element.children.length === 0) {
                  if (_.get(groupedItems, [element.title])) {
                    groupedItems[element.title] = _.concat(
                      groupedItems[element.title],
                      [o],
                    )
                  } else {
                    groupedItems[element.title] = [o]
                  }
                }
              })
            }
          } else {
            o.position.forEach(element => {
              if (element.children.length === 0) {
                if (_.get(groupedItems, [element.title])) {
                  groupedItems[element.title] = _.concat(
                    groupedItems[element.title],
                    [o],
                  )
                } else {
                  groupedItems[element.title] = [o]
                }
              }
            })
          }
        })
        this.propItems = groupedItems
      }
    },
  },
  updated: function() {
    this.$nextTick(function() {
      // Code that will run only after the
      // entire view has been re-rendered
      runFallback()
    })
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>