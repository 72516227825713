var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "group flex mb-6 w-full lg:w-1/2 lg:px-4",
      attrs: { href: _vm.item.url }
    },
    [
      _c("div", { staticClass: "w-1/3 md:w-1/4 mr-6 flex-shrink-0" }, [
        _c(
          "figure",
          {
            staticClass:
              "block relative aspect-ratio bg-white object-fit-fallback"
          },
          [
            !!_vm.item.featuredImage && !!_vm.item.featuredImage[0]
              ? _c("img", {
                  staticClass:
                    "absolute h-full object-cover transition lazyload",
                  attrs: {
                    src: "",
                    alt: _vm.item.featuredImage[0].title
                      ? _vm.item.featuredImage[0].title
                      : _vm.item.title,
                    "data-srcset":
                      _vm.item.featuredImage[0].imageOptimize.srcset,
                    "data-sizes": "auto"
                  }
                })
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sm:w-3/4 flex flex-col justify-between" }, [
        _c("h3", { staticClass: "sm:text-xl xl:text-2xl md:mb-4" }, [
          _c(
            "span",
            {
              staticClass:
                "border-b border-transparent group-hover:border-black transition"
            },
            [
              _vm._v(
                _vm._s(
                  _vm.item.title.length > 100
                    ? _vm.item.title.slice(0, 100) + "..."
                    : _vm.item.title
                )
              )
            ]
          )
        ]),
        _vm._v(" "),
        !!_vm.item.category && _vm.item.category.length > 0
          ? _c("div", [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.formatDate(_vm.item.postDate)) +
                    " " +
                    _vm._s(_vm.getCategoriesText(_vm.item))
                )
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }