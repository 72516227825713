var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative lg:px-2 xl:px-20 pb-12 max-w-screen m-auto",
      style: { minHeight: _vm.isLoading ? _vm.listMinHeight() : "auto" }
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-col xl:flex-row xl:justify-between mb-16" },
        [
          _c(
            "div",
            {
              staticClass:
                "hidden md:block -mx-3 overflow-x-scroll whitespace-no-wrap text-xl no-scroll"
            },
            _vm._l(_vm.alphabets, function(row) {
              return _c(
                "div",
                {
                  key: row,
                  staticClass:
                    "group hidden px-3 md:inline-block transition cursor-pointer",
                  class: {
                    "pointer-events-none opacity-25": !_vm.letterFilters.includes(
                      row
                    )
                  },
                  on: {
                    click: function($event) {
                      _vm.chosenLetter === row
                        ? (_vm.chosenLetter = "")
                        : (_vm.chosenLetter = row)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "inline-block border-b border-transparent group-hover:border-black",
                      class: { "border-black": _vm.chosenLetter === row }
                    },
                    [_vm._v(_vm._s(row))]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chosenLetter || _vm.selectedCategories.length > 0,
                  expression: "chosenLetter || selectedCategories.length > 0"
                }
              ],
              staticClass:
                "block border-b hover:border-transparent border-black cursor-pointer text-base self-start md:self-end mt-4 xl:mt-0",
              on: {
                click: function($event) {
                  return _vm.resetFilters()
                }
              }
            },
            [_vm._v("Clear filters")]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "justify-end items-baseline hidden sm:flex" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c("span", { staticClass: "hidden md:inline-block ml-8 text-base" }, [
            _vm._v("Show as")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-5" }, [
            _c("input", {
              attrs: {
                type: "radio",
                name: "people-radio",
                id: "image-radio",
                value: "image",
                checked: ""
              },
              on: {
                click: function($event) {
                  return _vm.openImageView()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "radio-btn", attrs: { for: "image-radio" } },
              [_vm._v("Image")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ml-5" }, [
            _c("input", {
              attrs: {
                type: "radio",
                name: "people-radio",
                id: "list-radio",
                value: "list"
              },
              on: {
                click: function($event) {
                  return _vm.openListView()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "radio-btn", attrs: { for: "list-radio" } },
              [_vm._v("List")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading || !_vm.isReady,
                expression: "isLoading || !isReady"
              }
            ],
            staticClass:
              "absolute w-full lg:pr-4 xl:pr-40 -my-8 md:-my-10 pt-50",
            staticStyle: { "z-index": "-1" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "loader-container flex justify-center items-center pt-16 pb-24"
              },
              [
                _c("div", { staticClass: "loader-animation inline-block" }, [
                  _c("span")
                ])
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [
          _vm.propItems
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isLoading,
                        expression: "!isLoading"
                      }
                    ],
                    staticClass: "people-view -my-8 md:-my-10"
                  },
                  [
                    _vm._l(_vm.positionsOrder, function(position) {
                      return [
                        _vm._l(_vm._.pick(_vm.propItems, [position]), function(
                          item,
                          key
                        ) {
                          return [
                            key !== "Management Team" ||
                            (key === "Management Team" &&
                              _vm.selectedCategories.length === 0) ||
                            (key === "Management Team" &&
                              _vm.selectedCategories
                                .map(function(val) {
                                  return val.id
                                })
                                .includes(7006))
                              ? _c(
                                  "div",
                                  { key: key, staticClass: "lg:mb-10" },
                                  [
                                    _c("div", { staticClass: "mb-6 md:mb-8" }, [
                                      _c(
                                        "span",
                                        { staticClass: "text-4xl md:text-6xl" },
                                        [_vm._v(_vm._s(key))]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "list-container flex flex-wrap sm:-mx-3 xl:-mx-4"
                                      },
                                      _vm._l(item, function(item, key) {
                                        return _c("category-search-item", {
                                          key: key,
                                          attrs: {
                                            item: item,
                                            "is-loading": _vm.isLoading,
                                            "section-type": _vm.sectionType
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    })
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "in-out" } },
        [
          !_vm.isLoading && _vm._.size(_vm.propItems) === 0
            ? [
                _c(
                  "h4",
                  {
                    staticClass:
                      "font-euclid-bold mb-6 mt-24 leading-slight text-3xl font-normal text-grey-dark max-w-2xs mx-auto py-8 text-center"
                  },
                  [_vm._v("No matching people found")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }