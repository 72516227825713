class CsrfToken {
    constructor() {
        const csrfTokenName = window.KROGERUS.csrfTokenName;
        const csrfInputs = document.querySelectorAll('input[name=' + csrfTokenName + ']');

        csrfInputs.forEach(input => {
            this.setCsrfValue(input);
        });
    }

    setCsrfValue(input) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.open('GET', '/actions/krogerus-module/default/csrf-token');
        xhr.onload = function() {
            if (xhr.status === 200) {
                input.value = xhr.response.csrfToken;
            }
        };
        xhr.send();
    }
}

new CsrfToken();
