<template>
  <transition name="fadeIn" mode="in-out">
    <div class="cardLoader px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="isLoading || !isReady" leave-stagger="500">
      <div class="pb-3 border-b border-black mb-12 line-large text-transparent">Title</div>
      <ul class="mb-8">
        <li v-for="(i, key) in ['one', 'two', 'three']" :key="key" class="mb-6 line-extra-large"><span class="text-4xl hover:opacity-50 transition">{{ i }}</span></li>
      </ul>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'EventCardLoadingIndicator',
  props: ['isLoading', 'isReady']
};
</script>
