//ANIMATED NUMBERS
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  function doAnimation(counter, current, number, text, interval) {
    window.setInterval(function() {
        if (isElementInViewport(counter)) {
          if (current < number) {
            var change = (number - 0) / 1000;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            current = current + change;
            counter.textContent = current + text;
          }
        }
      }, interval);
  }

  var target = '.counter-effect';

  // animate number counter
  if (document.querySelectorAll(target)) {
    var counters = document.querySelectorAll(target);

    for (var i = 0; i < counters.length; i++) {
      var counter = counters[i];
      var number = parseInt(counter.textContent.replace(/\D/g, ''));
      var text = counter.textContent.replace(/[0-9]/g, "");
      var current = 0;

      var interval = 1000 / number;

      counter.textContent = current + text;
      
      doAnimation(counter, current, number, text, interval);

      
    }
  }