import Typed from 'typed.js';

const animationElement = document.getElementById('typeAnimation');

if (animationElement) {
  const strings = animationElement.getAttribute(['data-strings'])
  const stringsArray = strings.split(", ");

  const typeAnimation = new Typed('#typeAnimation', {
    strings: stringsArray,
    typeSpeed: 45,
    backSpeed: 45,
    attr: 'placeholder',
    showCursor: true,
    bindInputFocusEvents: true,
    smartBackspace: true,
    loop: true
  });
}