import InfiniteScroll from 'infinite-scroll';
import { exists } from '../helpers/utils';

let loaderContainer = document.querySelector('.infinite-scroll-container');
let elem = document.querySelector('.article-feed');
let prevItem = document.querySelector('.prev-page');
let nextItem = document.querySelector('.next-page');
let loaderAnimation = document.querySelector('.loader-container');
let prevHtml = document.createDocumentFragment();

let selectors = {
  container: '.article-feed',
  nextItem: '.next-page',
  prevItem: '.prev-page',
  article: '.infinite-scroll-item',
  localStorageKey: 'feed_height',
  loading: 'loading'
};

if (exists(prevItem)) {
  let localStorage = window.localStorage.getItem(selectors.localStorageKey);
  if (localStorage) {
    elem.style.height = `${localStorage}px`;
  }
  let container = document.querySelector(selectors.container);
  loaderContainer.classList.add(selectors.loading);
  renderPrevious(prevItem);
}
function getFeedHeight() {
  let container = document.querySelector(selectors.container);
  window.localStorage.setItem(selectors.localStorageKey, container.offsetHeight);
}

function buildPreviousHtml(response) {
  let items = response.querySelectorAll(selectors.article);
  if (!items || !items.length) {
    return;
  }

  let container = prevHtml;
  let currentItems = container.querySelectorAll(selectors.article);
  let firstItem = currentItems[0];
  let prevItems = getItemsFragment(items);

  // Prepend the html
  container.insertBefore(prevItems, firstItem);
}

function getItemsFragment(items) {
  // add items to fragment
  let fragment = document.createDocumentFragment();
  for (let i = 0; items && i < items.length; i++) {
    fragment.appendChild(items[i]);
  }
  return fragment;
}

function renderPrevious(prevItem) {
  let path = prevItem.getAttribute('href');

  let onLoad = (response) => {

    // Build previous page items html
    buildPreviousHtml(response);

    // check if we have more previous pages to load
    let prevItems = response.querySelector(selectors.prevItem);
    if (exists(prevItems)) {
      // build more previous items
      renderPrevious(prevItems);
    } else {
      // we have no more previous items, lets add the html
      prependPreviousItems();
      loaderContainer.classList.remove(selectors.loading);
    }
  };

  let onError = (response) => {
    console.log('Error', response);
  };

  let onLast = (response) => {
    console.log('onLast', response);
  };

  // Get the html
  request(`${path}`, 'document', onLoad, onError, onLast);
}

function prependPreviousItems(response) {
  let container = document.querySelector(selectors.container);
  let currentItems = container.querySelectorAll(selectors.article);
  let firstItem = currentItems[0];
  let prevItems = prevHtml;

  // Hide the loader
  document.querySelector(selectors.prevItem).classList.add('hidden');

  // Prepend the html
  container.insertBefore(prevItems, firstItem);
  container.style.height = null;
  loaderContainer.classList.remove(selectors.loading);
}

function request(url, responseType, onLoad, onError, onLast) {
  let req = new XMLHttpRequest();
  req.open('GET', url, true);
  // set responseType document to return DOM
  req.responseType = responseType || '';

  // set X-Requested-With header to check that is ajax request
  req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  req.onload = function () {
    if (req.status == 200) {
      onLoad(req.response);
    } else if (req.status == 204) {
      onLast(req.response);
    } else {
      // not 200 OK, error
      let error = new Error(req.statusText);
      onError(error);
    }
  };

  // Handle network errors
  req.onerror = function () {
    let error = new Error('Network error requesting ' + url);
    onError(error);
  };

  req.send();
  }

if (elem && nextItem) {
  let infScroll = new InfiniteScroll(elem, {
    path: selectors.nextItem,
    append: selectors.article,
    prefill: true,
    scrollThreshold: 700,
    // history: 'push'
  });

  infScroll.on( 'history', (title, path) => {
    history.replaceState(null, title, path);
  });

  // update the feed height if a user refreshes or goes to another page
  infScroll.on('append', (response, path) => {
    getFeedHeight();
  });

  // Last page is loaded, remove the loader
  infScroll.on( 'last', ( response, path ) => {
    loaderAnimation.classList.add('hidden');
  });
}