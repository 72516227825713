<template>
  <a class="group flex mb-6 w-full lg:w-1/2 lg:px-4" :href="item.url">

    <div class="w-1/3 md:w-1/4 mr-6 flex-shrink-0">
      <figure class="block relative aspect-ratio bg-white object-fit-fallback">
        <img
          v-if="!!item.featuredImage && !!item.featuredImage[0]"
          class="absolute h-full object-cover transition lazyload"
          src=""
          :alt="item.featuredImage[0].title ? item.featuredImage[0].title : item.title"
          :data-srcset="item.featuredImage[0].imageOptimize.srcset"
          data-sizes="auto"
        >
      </figure>
    </div>
    <div class="sm:w-3/4 flex flex-col justify-between">
      <h3 class="sm:text-xl xl:text-2xl md:mb-4"><span class="border-b border-transparent group-hover:border-black transition">{{ item.title.length > 100 ? item.title.slice(0, 100) + '...' : item.title }}</span></h3>
      <div v-if="!!item.category && item.category.length > 0">
        <span>{{ formatDate(item.postDate) }} {{ getCategoriesText(item) }}</span>
      </div>
    </div>
  </a>
</template>

<script>
import Vue from 'vue';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import moment from 'moment';

moment.locale('en');

export default {
  name: 'SearchPageArticleCard',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    last: {
      type: Boolean
    }
  },
  methods: {
    formatDate: function(date) {
    console.log('date:', date);

      const momentDate = moment(date);

      return momentDate.format('D MMMM, YYYY')
    },
    getCategoriesText: function(item) {
      let text = item.category.length > 1 ? ' - ' : '';
      item.category.forEach((category, key) => {
        if (category.id === 12) { return; }
        text = text + category.title;
        if (key !== item.category.length - 1) {
          text = text + ' | '
        }
      });
      text = text.length > 31 ? text.slice(0, 31) + '...' : text;
      return text;
    }
  }
};
</script>