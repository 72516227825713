<template>
  <div :style="{ minHeight: isLoading ? listMinHeight() : 'auto' }">
    <div class="relative mx-auto">
      <div class="absolute w-full h-full" style="z-index: -1">
        <transition name="fadeIn" mode="in-out">
          <div class="loader-container flex justify-center items-center pt-16 pb-24" v-show="isLoading || !isReady">
            <div class="loader-animation inline-block">
              <span></span>
            </div>
          </div>
        </transition>
      </div>
      <transition-group name="fadeIn" mode="in-out">
        <template v-if="Object.keys(items).length > 0">
          <section v-if="!!items['people'] && items['people'].length > 0" :key="0" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">People results</div>
            <div class="flex flex-wrap mb-8 md:-mx-4 lg:-mx-3">
              <search-page-person-card
                v-for="(i) in items['people']"
                :key="i.id"
                :item="i"
                :is-loading="isLoading"
              ></search-page-person-card>
            </div>
            <a class="move inline-flex items-end" :href="(!!searchQuery) ? peopleLink + '?q='+searchQuery : peopleLink">
              <span class="text-4xl mr-6">View all people with "{{ searchQuery }}"</span>
              <span>
                <svg width="64px" height="31px" viewBox="0 0 64 31">
                  <g transform="translate(-1277.000000, -226.000000)">
                    <g transform="translate(1004.000000, 92.000000)">
                      <g transform="translate(0.000000, 114.000000)">
                        <g transform="translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)">
                          <path d="M23,44 L45,44" class="stroke-current" stroke-width="2"></path>
                          <path d="M44,23 L44,45" class="stroke-current" stroke-width="2"></path>
                          <polygon class="fill-current" transform="translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) " points="-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </a>
          </section>
          <section v-if="!!items['openPositions'] && items['openPositions'].length > 0" :key="1" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">Open positions</div>
            <ul class="mb-8">
              <li v-for="(i) in items['openPositions']" :key="i.id" class="mb-6"><a class="text-4xl border-b border-transparent hover:border-black transition" :href="i.jobLink" target="_blank">{{ i.title }}</a></li>
            </ul>
          </section>
          <section v-if="!!items['cases'] && items['cases'].length > 0" :key="2" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">Highlights results</div>
            <div class="flex flex-wrap mb-12 lg:-mx-4">
              <search-page-article-card
                v-for="(i) in items['cases']"
                :key="i.id"
                :item="i"
                :is-loading="isLoading"
              ></search-page-article-card>
            </div>
            <a class="move inline-flex items-end" :href="(!!searchQuery) ? casesLink + '?q='+searchQuery : casesLink">
              <span class="text-4xl mr-6">View all highlights with "{{ searchQuery }}"</span>
              <span>
                <svg width="64px" height="31px" viewBox="0 0 64 31">
                  <g transform="translate(-1277.000000, -226.000000)">
                    <g transform="translate(1004.000000, 92.000000)">
                      <g transform="translate(0.000000, 114.000000)">
                        <g transform="translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)">
                          <path d="M23,44 L45,44" class="stroke-current" stroke-width="2"></path>
                          <path d="M44,23 L44,45" class="stroke-current" stroke-width="2"></path>
                          <polygon class="fill-current" transform="translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) " points="-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
              </svg>
              </span>
            </a>
          </section>
          <section v-if="!!items['news'] && items['news'].length > 0" :key="3" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">News results</div>
            <div class="flex flex-wrap mb-12 lg:-mx-4">
              <search-page-article-card
                v-for="(i) in items['news']"
                :key="i.id"
                :item="i"
                :is-loading="isLoading"
              ></search-page-article-card>
            </div>
            <a class="move inline-flex items-end" :href="(!!searchQuery) ? newsLink + '?q='+searchQuery : newsLink">
              <span class="text-4xl mr-6">View all news with "{{ searchQuery }}"</span>
              <span>
                <svg width="64px" height="31px" viewBox="0 0 64 31">
                  <g transform="translate(-1277.000000, -226.000000)">
                    <g transform="translate(1004.000000, 92.000000)">
                      <g transform="translate(0.000000, 114.000000)">
                        <g transform="translate(304.500000, 35.500000) rotate(-45.000000) translate(-304.500000, -35.500000) translate(282.000000, 13.000000)">
                          <path d="M23,44 L45,44" class="stroke-current" stroke-width="2"></path>
                          <path d="M44,23 L44,45" class="stroke-current" stroke-width="2"></path>
                          <polygon class="fill-current" transform="translate(22.332738, 22.332738) rotate(45.000000) translate(-22.332738, -22.332738) " points="-8.16726189 23.3327381 52.8327381 23.3327381 52.8327381 21.3327381 -8.16726189 21.3327381"></polygon>
                        </g>
                      </g>
                    </g>
                  </g>
              </svg>
              </span>
            </a>
          </section>
          <section v-if="!!items['events'] && items['events'].length > 0" :key="3" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">Event results</div>
            <div class="flex flex-wrap mb-12 lg:-mx-4">
              <search-page-article-card
                v-for="(i) in items['events']"
                :key="i.id"
                :item="i"
                :is-loading="isLoading"
              ></search-page-article-card>
            </div>
          </section>
          <section v-if="!!items['pages'] && items['pages'].length > 0" :key="4" class="px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto" v-show="!isLoading">
            <div class="pb-3 border-b border-black mb-12">Page results</div>
            <ul class="mb-8">
              <li v-for="(i) in items['pages']" :key="i.id" class="mb-6"><a class="text-4xl border-b border-transparent hover:border-black transition" :href="i.url">{{ i.title }}</a></li>
            </ul>
          </section>
        </template>
      </transition-group>
      <transition name="fadeIn" mode="in-out">
        <template v-if="!isLoading && items.length === 0">
          <h4 class="heading-4 uppercase mx-auto py-8 text-center">No results found</h4>
        </template>
      </transition>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import SearchPageArticleCard from './SearchPageArticleCard.vue'
import SearchPagePersonCard from './SearchPagePersonCard.vue'
import SearchPageLoadingIndicator from './SearchPageLoadingIndicator.vue';

export default {
  name: 'SearchPageList',
  props: {
    items: {
      type: Object,
      default: {},
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isReady: {
      type: Boolean,
      default: false
    },
    peopleLink: {
      type: String,
      default: ''
    },
    newsLink: {
      type: String,
      default: ''
    },
    casesLink: {
      type: String,
      default: ''
    },
    searchQuery: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchPageArticleCard,
    SearchPagePersonCard,
    SearchPageLoadingIndicator
  },
  methods: {
    listMinHeight: function() {
      const rowHeight = 280;
      const rowAmount = 3;

      return rowAmount * rowHeight + 'px';
    }
  }
};
</script>
