<template>
  <transition name="fadeIn" mode="in-out">
    <div class="md:px-3 xl:px-4 w-full md:w-1/2 lg:w-1/4 mb-6 md:mb-8 overflow-hidden people-card-outer text-base">
      <a class="block" :href="item.url" >
        <figure class="aspect-ratio-70 relative mb-6 bg-gray-image object-fit-fallback">
          <template v-if="!!item.image && !!item.image[0]">
            <img
              class="lazyload absolute top-0 left-0 w-full h-full block object-cover"
              src=""
              :data-srcset="item.image[0].imageOptimize.srcset"
              :alt="item.image[0].title ? item.image[0].title : item.title"
              data-sizes="auto"
            >
          </template>
          <template v-else>
            <img
              class="lazyload absolute top-0 left-0 w-full h-full block object-cover"
              src="/assets/img/person-placeholder.jpg"
              srcset=""
              :alt="item.title"
            >
          </template>
        </figure>
      </a>
      <div class="mb-1 md:mb-2">
        <a class="border-b border-transparent hover:border-black transition" :href="item.url">{{ item.title }}</a>
      </div>
      <div class="mb-2 md:mb-4">{{ item.jobTitle.length > 0 ? item.jobTitle[0].title : '' }}</div>
      <div class="mb-1 md:mb-2 ellipsis"><a class="border-b border-transparent hover:border-black transition" :href="'mailto:' + item.email">{{ item.email }}</a></div>
      <div class="mb-2"><a class="border-b border-transparent hover:border-black transition" :href="'tel:' + item.mobileNumber">{{ item.mobileNumber }}</a></div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import moment from 'moment';

moment.locale('en');

export default {
  name: 'CategorySearchItem',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
  },
};
</script>
