var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "lg:px-2 xl:px-20 pt-16 md:pb-12 max-w-screen m-auto" },
    [
      _c("div", { staticClass: "flex items-center mb-16" }, [
        _c(
          "span",
          {
            staticClass: "mr-6",
            on: {
              click: function($event) {
                return _vm.performSearch()
              }
            }
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-8 md:w-10 h-8 md:h-10",
                attrs: { viewBox: "0 0 42 42" }
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd"
                    }
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "translate(-82.000000, -698.000000)",
                          "fill-rule": "nonzero",
                          stroke: "#000000",
                          "stroke-width": "2"
                        }
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              transform: "translate(76.000000, 693.000000)"
                            }
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  transform:
                                    "translate(30.048179, 29.344931) rotate(-315.000000) translate(-30.048179, -29.344931) translate(4.548179, 13.344931)"
                                }
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    cx: "15.5903298",
                                    cy: "15.5903298",
                                    r: "15.5903298"
                                  }
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M31.1806597,15.5903298 L49.554977,15.5903298"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "w-4/5 search-wrapper",
            attrs: { id: "people-search", action: "", method: "post" },
            on: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c("input", {
              staticClass:
                "people-search-input text-xl md:text-5xl w-full tracking-wider md:tracking-normal",
              attrs: {
                "aria-label": "Search",
                type: "search",
                placeholder: "Search for an expert"
              },
              domProps: { value: _vm.query },
              on: {
                input: function($event) {
                  return _vm.$emit("update:query", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("button", {
              staticClass: "hidden",
              attrs: { type: "submit", value: "Submit" },
              on: { click: _vm.performSearch }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex flex-wrap -mx-3 xl:-mx-4 items-stretch" },
        [
          _vm._l(_vm.categoryFilters, function(categoryGroup, key) {
            return [
              _c(
                "div",
                {
                  key: categoryGroup.id + key,
                  staticClass:
                    "w-full md:w-1/2 lg:w-1/4 px-3 xl:px-4 mb-8 lg:mb-0"
                },
                [
                  _c("div", { staticClass: "relative h-full" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "h-full flex justify-between items-center pb-3 cursor-pointer menu-toggle border-b border-black"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "inline-block opacity-50 text-xl truncate",
                            class: {
                              "opacity-100": _vm.selectedCategories.find(
                                function(o) {
                                  return (
                                    _vm._.get(o, "parentCategory.id") ===
                                    categoryGroup.id
                                  )
                                }
                              )
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.selectedCategories.find(function(o) {
                                  return (
                                    _vm._.get(o, "parentCategory.id") ===
                                    categoryGroup.id
                                  )
                                })
                                  ? _vm.selectedCategories.find(function(o) {
                                      return (
                                        o.parentCategory.id === categoryGroup.id
                                      )
                                    }).title
                                  : categoryGroup.handle == "position"
                                  ? "All our people"
                                  : "Any " + categoryGroup.groupTitle
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "pointer-events-none chevron rotate-180",
                            staticStyle: { width: "18px", height: "9px" }
                          },
                          [
                            _c("svg", { attrs: { viewBox: "0 0 29 14" } }, [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Layout-Desktop",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "practices-single",
                                        transform:
                                          "translate(-1183.000000, -913.000000)",
                                        stroke: "#000000"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "Group-12-Copy-2",
                                            transform:
                                              "translate(517.000000, 912.000000)"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "Path-2",
                                                transform:
                                                  "translate(666.922836, 1.500000)"
                                              }
                                            },
                                            [
                                              _c("polyline", {
                                                attrs: {
                                                  transform:
                                                    "translate(13.500000, 13.500000) scale(-1, -1) rotate(-315.000000) translate(-13.500000, -13.500000) ",
                                                  points: "4 23 23 23 23 4"
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "hidden" }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "18px",
                                height: "16px",
                                viewBox: "0 0 18 16"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Layout-Desktop",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "People",
                                        transform:
                                          "translate(-356.000000, -729.000000)",
                                        "fill-rule": "nonzero",
                                        stroke: "#000000"
                                      }
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "Group-6",
                                            transform:
                                              "translate(80.000000, 719.000000)"
                                          }
                                        },
                                        [
                                          _c(
                                            "g",
                                            { attrs: { id: "Group-9" } },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    id: "Group-17",
                                                    transform:
                                                      "translate(269.000000, 2.188077)"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "g",
                                                    {
                                                      attrs: {
                                                        id: "Group-15",
                                                        transform:
                                                          "translate(16.500000, 16.000000) rotate(-315.000000) translate(-16.500000, -16.000000) translate(5.000000, 5.000000)"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "g",
                                                        {
                                                          attrs: {
                                                            id: "Group-16",
                                                            transform:
                                                              "translate(0.000000, -0.000000)"
                                                          }
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d:
                                                                "M0.303300859,10.6966991 L22.3033009,10.6966991",
                                                              id: "Line"
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("path", {
                                                            attrs: {
                                                              d:
                                                                "M11,-2.72848411e-11 L11,22",
                                                              id: "Line"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute w-full pl-6 pr-3 bg-black text-white shadow-md z-50 hidden dropdown-menu overflow-hidden",
                        staticStyle: { left: "0", top: "0" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "h-64 overflow-y-scroll category-filter"
                          },
                          [
                            _c(
                              "span",
                              {
                                key: key,
                                staticClass:
                                  "block py-1 hover:opacity-50 transition cursor-pointer text-xl mt-6",
                                on: {
                                  click: function($event) {
                                    return _vm.categorySelector(
                                      undefined,
                                      categoryGroup
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    categoryGroup.handle == "position"
                                      ? "All our people"
                                      : "Any " + categoryGroup.groupTitle
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(categoryGroup.categories, function(
                              row,
                              key
                            ) {
                              return [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !row.children,
                                        expression: "!row.children"
                                      }
                                    ],
                                    key: row.id + key,
                                    staticClass:
                                      "block pt-2 pb-1 hover:opacity-50 transition cursor-pointer text-xl",
                                    class: {
                                      "opacity-50": !!_vm._.find(
                                        _vm.selectedCategories,
                                        { id: row.id }
                                      ),
                                      "":
                                        key !==
                                        categoryGroup.categories.length - 1,
                                      "opacity-75 mt-2 mb-1": row.children,
                                      "mt-2":
                                        key !== 0 &&
                                        categoryGroup.categories[key - 1]
                                          .parent &&
                                        !row.parent,
                                      "mb-6":
                                        key ===
                                        categoryGroup.categories.length - 1
                                    },
                                    on: {
                                      click: function($event) {
                                        !!row &&
                                          _vm.categorySelector(
                                            row,
                                            categoryGroup
                                          )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.title))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cat,
                            expression: "cat"
                          }
                        ],
                        staticClass:
                          "md:hidden appearance-none absolute top-0 right-0 w-full h-full opacity-0 z-10 text-base focus:outline-none focus:bg-white focus:border-white",
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.cat = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.onChange($event)
                            }
                          ]
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            domProps: {
                              value: {
                                title: undefined,
                                id: undefined,
                                slug: undefined,
                                parentCategory: { id: categoryGroup.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                categoryGroup.handle == "position"
                                  ? "All our people"
                                  : "Any " + categoryGroup.groupTitle
                              )
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(categoryGroup.categories, function(row, key) {
                          return [
                            _c(
                              "option",
                              {
                                key: row.id + key,
                                domProps: {
                                  selected: !!_vm._.find(
                                    _vm.selectedCategories,
                                    { id: row.id }
                                  ),
                                  value: {
                                    title: row.title,
                                    id: row.id,
                                    slug: row.slug,
                                    children: row.children,
                                    parentCategory: { id: categoryGroup.id }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.title))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }