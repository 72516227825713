var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass:
        "group block w-full md:w-1/3 lg:w-1/6 md:px-3 xl:px-4 mb-6 md:mb-8 lg:mb-10",
      attrs: { href: _vm.item.url }
    },
    [
      _c(
        "figure",
        {
          staticClass:
            "mb-3 md:mb-4 block relative aspect-ratio-70 bg-gray-image"
        },
        [
          !!_vm.item.image && !!_vm.item.image[0]
            ? [
                _c("img", {
                  staticClass:
                    "lazyload absolute top-0 left-0 w-full h-full block object-cover transition lazyload",
                  attrs: {
                    src: "",
                    alt: _vm.item.image[0].title
                      ? _vm.item.image[0].title
                      : _vm.item.title,
                    "data-srcset": _vm.item.image[0].imageOptimize.srcset,
                    "data-sizes": "auto"
                  }
                })
              ]
            : [
                _c("img", {
                  staticClass:
                    "lazyload absolute top-0 left-0 w-full h-full block object-cover",
                  attrs: {
                    src: "/assets/img/person-placeholder.jpg",
                    alt: _vm.item.title
                  }
                })
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mb-1" }, [
        _c(
          "span",
          {
            staticClass: "border-b border-transparent group-hover:border-black"
          },
          [_vm._v(_vm._s(_vm.item.title))]
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          _vm._s(_vm.item.jobTitle.length > 0 ? _vm.item.jobTitle[0].title : "")
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }