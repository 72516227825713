var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading || !_vm.isReady,
            expression: "isLoading || !isReady"
          }
        ],
        staticClass:
          "cardLoader px-6 md:px-8 xl:px-20 mb-18 max-w-screen mx-auto",
        attrs: { "leave-stagger": "500" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "pb-3 border-b border-black mb-12 line-large text-transparent"
          },
          [_vm._v("Title")]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "mb-8" },
          _vm._l(["one", "two", "three"], function(i, key) {
            return _c(
              "li",
              { key: key, staticClass: "mb-6 line-extra-large" },
              [
                _c(
                  "span",
                  { staticClass: "text-4xl hover:opacity-50 transition" },
                  [_vm._v(_vm._s(i))]
                )
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }