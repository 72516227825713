let currentScrollPosition = 0;
let isLocked = false;

const specifiedElement = document.querySelector('.slide-menu');
const html = document.getElementsByTagName('html')[0];
const lockTriggers = document.getElementsByClassName('lockTrigger');

for (let i = 0; i < lockTriggers.length; i += 1) {
  lockTriggers[i].addEventListener('click', () => {
    if (isLocked === false) {
      currentScrollPosition = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
      setTimeout(function () {
        html.style.scrollBehavior = 'unset';
        document.body.classList.add('lock-screen');
      }, 300);
      isLocked = true;
    } else {
      document.body.classList.remove('lock-screen');
      document.body.scrollTop = document.documentElement.scrollTop = currentScrollPosition;
      html.style.scrollBehavior = 'smooth';
      isLocked = false;
    }
  });
}
document.addEventListener('click', function (event) {
  const checkBox = document.getElementById('show-menu');
  if (event.target.classList.contains('nav-toggle')) {
    if (checkBox.checked === true) {
      checkBox.checked = false;
      document.body.classList.remove('lock-screen');
      document.body.scrollTop = document.documentElement.scrollTop = currentScrollPosition;
      html.style.scrollBehavior = 'smooth';
      isLocked = false;

    } else {
      checkBox.checked = true;
    }
  } else {
    if (checkBox.checked === true) {
      const isClickInside = specifiedElement.contains(event.target);
      if (!isClickInside) {
        checkBox.checked = false;
        document.body.classList.remove('lock-screen');
        document.body.scrollTop = document.documentElement.scrollTop = currentScrollPosition;
        html.style.scrollBehavior = 'smooth';
        isLocked = false;
      }
    }
  }
});