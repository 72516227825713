// Get all sections that have an ID defined
const sections = document.querySelectorAll("section[id^=subnav-anchor-]");
const subnav = document.querySelector(".subnavigation");

// Add an event listener listening for scroll
window.addEventListener("scroll", navHighlighter);

function navHighlighter() {

  // Get current scroll position
  let scrollY = window.pageYOffset;

  // Now we loop through sections to get height, top and ID values for each
  sections.forEach((current, index) => {

    const contentSection = current;
    const sectionHeight = contentSection.nextElementSibling.offsetHeight;
    const sectionTop = contentSection.offsetTop - 50;
    let sectionId = current.getAttribute("id");

    /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
    if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
      document.querySelector(".subnavigation a[href*=" + sectionId + "]").style.opacity = '1';
    } else {
      document.querySelector(".subnavigation a[href*=" + sectionId + "]").style.opacity = '0.5';
    }

    // Display the subnav at the first anchor
    if (index === 0) {
      const firstSection = current;
      if (scrollY > firstSection.offsetTop - 50) {
        subnav.classList.add("active");
      } else {
        subnav.classList.remove("active");
      }
    }

  });
}
