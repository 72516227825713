import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import { configureApi, searchPageApiToken, apiUrl, getQueryParam, executeQuery, cancelExecution } from './searchCommon';
import SearchPageList from '../../vue/SearchPageList.vue'

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, searchPageApiToken)),
    searchQuery: '',
    searchResults: {},
    searchSorting: 'postdate desc',
    isLoading: true,
    isReady: false,
    isFromParam: false,
    window: {
      width: 0,
      height: 0
    }
  };
}

let filterChangeTimer;

let generalSearch = function () {
  console.log("Initializing general search...");

  const searchEntries = ['people_person_Entry', 'articles_case_Entry', 'articles_article_Entry', 'pages_page_Entry', 'pages_landingPage_Entry', 'about_about_Entry', 'articlesLanding_articlesLanding_Entry', 'peopleLanding_peopleLanding_Entry', 'expertiseLanding_expertiseLanding_Entry', 'openPositions_career_Entry', 'traineeProgrammes_traineeProgrammes_Entry', 'lawyers_lawyers_Entry', 'sustainability_sustainability_Entry', 'alumni_alumni_Entry', 'alumniSubscription_alumniSubscription_Entry', 'events_default_Entry'];
  let searchQueries = '';

  _.each(searchEntries, (entryType) => {
    let orderBy = '"score"';
    if (entryType === 'articles_case_Entry' || entryType === 'articles_article_Entry' || entryType === 'articlesLanding_articlesLanding_entry') {
      orderBy = '"postDate desc"';
    }

    searchQueries += `
      ${entryType}: entries( limit: 6 offset: $offset orderBy: ${orderBy} search: $needle) {
        ... on ${entryType} {
          sectionId
          sectionHandle
          typeHandle
          typeId
          id
          title
          url
          postDate
          __typename
          ${['articles_article_Entry', 'articles_case_Entry'].includes(entryType) ? `
          category {
            id
            title
          }
          colorEffectInCard
          featuredImage {
            title
            ...on general_Asset {
              imageOptimize {
                src,
                srcset
                placeholderBox
              }
            }
          }
          ` : ''}
          ${['events_default_entry'].includes(entryType) ? `
          featuredImage {
            title
            ...on general_Asset {
              imageOptimize {
                src,
                srcset
                placeholderBox
              }
            }
          }
          ` : ''}
          ${entryType === 'people_person_Entry' ? `
          jobTitle {
            title
          }
          position {
            title
          }
          image {
            title
            ...on general_Asset {
              imageOptimize {
                src,
                srcset
                placeholderBox
              }
            }
          }
          ` : ''}
          ${entryType === 'openPositionsCareer_openPositionsCareer_entry' ? `
          jobLink
          ` : ''}
        }
      }
    `;
  });

  // The query to search for entries in Craft
  const searchQuery =
    `
  query searchQuery($needle: String, $offset: Int)
    {
      ${searchQueries}
    }
  `;

  new Vue({
    el: document.getElementById('searchPage'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      SearchPageList,
    },
    beforeCreate: function () {
    },
    created: function () {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      const searchParam = getQueryParam('q');
      if (!!searchParam) {
        this.searchQuery = searchParam.replace(/\+/g, ' ');
        this.isFromParam = true;
      }
    },
    mounted: function () {
      this.performSearch();
    },
    updated: function () {
    },
    destroyed: function () {
      document.removeEventListener('click', this.closeSearchDropdown);
      window.removeEventListener('resize', this.handleResize);
    },
    watch: {
      searchQuery: function (val, oldVal) {
        if (!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData(), 'meta');
        }
        this.performSearch();
      },
      searchSorting: function () {
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      performSearch() {
        let self = this;

        if (self.searchQuery === '') {
          self.setHistory();
          self.searchResults = {};
          self.isSearchDropdownOpen = false;
          return true;
        }

        self.isLoading = true;
        self.searchResults = {};
        self.setHistory();
        const searchTitle = !!self.searchQuery ? `title:*${self.searchQuery}* OR jobTitle:*${self.searchQuery}*` : '';

        const searchString = _.compact([searchTitle]).join(' OR ');

        // Set the variables we will pass in to our query
        const variables = {
          needle: searchString,
          orderBy: 'postDate desc'
        };
        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function () {
          cancelExecution();

          executeQuery(self.searchApi, searchQuery, variables, (data) => {

            let entries = [];
            _.forEach(data.data, function (section) {
              entries = [...entries, section];
            })
            const dataPath = { 'entries': _.flatten(entries) };
            const singles = _.map(_.filter(dataPath.entries, (e) => {
              return !_.isEmpty(e, 'typeHandle') && e.typeHandle !== 'article' && e.typeHandle !== 'person' && e.typeHandle !== 'case' && e.typeHandle !== 'default'
            }), o => { return { ...o, section: { sectionHandle: 'pages' } } });
            const pages = _.map(_.get(_.groupBy(dataPath.entries, 'typeHandle'), 'page'), o => { return { ...o, section: { sectionHandle: 'pages' } } });
            const people = _.map(_.get(_.groupBy(dataPath.entries, 'typeHandle'), 'person'), o => { return { ...o, section: { sectionHandle: 'people' } } });
            let groupedResults = _.groupBy(dataPath.entries, 'sectionHandle');
            const cases = _.map(_.filter(groupedResults.articles, o => { return o.typeHandle === 'case' }), o => { return { ...o, section: { sectionHandle: 'cases' } } });
            const news = _.map(_.filter(groupedResults.articles, o => { return o.typeHandle === 'article' }), o => { return { ...o, section: { sectionHandle: 'news' } } });
            const events = _.map(_.filter(groupedResults.articles, o => { return o.typeHandle === 'default' }), o => { return { ...o, section: { sectionHandle: 'events' } } });
            if (cases.length > 0) {
              groupedResults = {
                ...groupedResults,
                cases: cases,
              }
            }
            if (news.length > 0) {
              groupedResults = {
                ...groupedResults,
                news: news,
              }
            }
            if (pages.length > 0) {
              groupedResults = {
                ...groupedResults,
                pages: pages,
              }
            }
            if (events.length > 0) {
              groupedResults = {
                ...groupedResults,
                events: events,
              }
            }
            if (singles.length > 0) {
              groupedResults = {
                ...groupedResults,
                pages: _.concat(groupedResults.pages, singles),
              }
            }
            if (people.length > 0) {
              groupedResults = {
                ...groupedResults,
                people,
              }
            }
            groupedResults = _.groupBy(_.flatten(_.map(groupedResults, o => {
              return _.take(o, 6)
            })), 'section.sectionHandle')
            self.searchResults = groupedResults;
            if (!self.isReady) {
              self.isReady = true;
            }

            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function () {
        let self = this;
        let paramString = '';
        if (!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function () {
        let top = 0;

        setTimeout(function () {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
      handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
      }
    },
  });
};

!!document.getElementById('searchPage') && generalSearch();
