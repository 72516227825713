import Flickity from 'flickity-fade';

export default function initFlickity() {

  const elem = document.querySelector('.main-carousel');
  const nextButton = document.querySelector('.flickity-next');
  const prevButton = document.querySelector('.flickity-prev');


  if (document.querySelector('.articles-slider')) {
    if (elem) {
      const flkty = new Flickity(elem, {
        cellAlign: 'left',
        contain: false,
        prevNextButtons: false,
        autoPlay: 4000,
        wrapAround: true,
        lazyLoad: true,
        fade: true,
      });
    }

  } else {

    if (elem) {
      const flkty = new Flickity(elem, {
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        adaptiveHeight: true,
        prevNextButtons: false,
        pageDots: false
      });
      if (nextButton) {
        nextButton.addEventListener('click', function () {
          flkty.next(true)
        })
      }
      if (prevButton) {
        prevButton.addEventListener('click', function () {
          flkty.previous(true)
        })
      }
    }
  }

}