const scrollLinks = document.querySelectorAll('.hero-scroll-link');

if (scrollLinks) {
  scrollLinks.forEach(link => {
    link.onclick = () => {
      const sections = document.querySelectorAll('section')
      if (sections[1]) {
        sections[1].scrollIntoView({ behavior: 'smooth' })
      }
    }
  });
}