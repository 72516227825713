<template>
  <a class="group block w-full md:w-1/3 lg:w-1/6 md:px-3 xl:px-4 mb-6 md:mb-8 lg:mb-10" :href="item.url">
    <figure class="mb-3 md:mb-4 block relative aspect-ratio-70 bg-gray-image">
      <template v-if="!!item.image && !!item.image[0]">
        <img
          class="lazyload absolute top-0 left-0 w-full h-full block object-cover transition lazyload"
          src=""
          :alt="item.image[0].title ? item.image[0].title : item.title"
          :data-srcset="item.image[0].imageOptimize.srcset"
          data-sizes="auto"
        >
      </template>
      <template v-else>
        <img
          class="lazyload absolute top-0 left-0 w-full h-full block object-cover"
          src="/assets/img/person-placeholder.jpg"
          :alt="item.title"
        >
      </template>
    </figure>
    <div class="mb-1"><span class="border-b border-transparent group-hover:border-black">{{ item.title }}</span></div>
    <div>{{ item.jobTitle.length > 0 ? item.jobTitle[0].title : '' }}</div>
  </a>

</template>

<script>
import Vue from 'vue';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import moment from 'moment';

moment.locale('en');

export default {
  name: 'SearchPagePersonCard',
  props: {
    item: {
      type: Object
    },
    isLoading: {
      type: Boolean
    },
    last: {
      type: Boolean
    }
  },
};
</script>