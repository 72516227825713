<template>
  <transition name="fadeIn" mode="in-out">
    <div class=" md:px-3 xl:px-4 w-full md:w-1/2 lg:w-1/4 mb-6 md:mb-8 overflow-hidden people-card-outer text-base" >
        <div class="aspect-ratio-70 relative mb-6 bg-gray-image">
          <div class="cardLoader__image absolute inset-0 w-full h-full block object-cover"></div>
        </div>
        <div class="line-medium mb-2">Name</div>
        <div class="line-small mb-4">Title</div>
        <h3 class="line-medium-large mb-2 ellipsis">Email</h3>
        <div class="line-medium mb-2">Phone</div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'CardLoadingIndicator',
  props: ['isLoading', 'isReady', 'sectionType']
};
</script>
