// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss';

import "core-js/stable";
import "regenerator-runtime/runtime";

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes';
import 'picturefill';

import smoothscroll from 'smoothscroll-polyfill';

// Our own helpers
import runFallback from './helpers/objectfitFallback'
import './helpers/iePolyfills'
import './helpers/classList'
import reframe from './helpers/iframe'

// Our own components
import './components/accordion'
import './components/categorySearch';
import initFlickity from './components/flickitySlider';
import './components/searchPageSearch';
import './components/heroScrollLinks';
import './components/infiniteScroll';
import './components/menuToggle'
import './components/numberAnimation'
import './components/primaryNavigation'
import './components/textAnimation'
import './components/csrf'
import './components/sustainabilityNav'
import './components/gridAccordion'
import Flickity from 'flickity';


// Window onload (after all dom and images are loaded completely)
window.onload = function () {

  smoothscroll.polyfill();

  runFallback();
  // responsive iframes
  reframe(document.querySelectorAll('.wysiwyg iframe')) // Pass a live node list

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  initFlickity();

  // testimonals flickity
  if (document.querySelector('.testimonial-slider')) {
    /* eslint-disable no-unused-vars */
    const testimonialsFlkty = new Flickity('.testimonial-slider', {
      // options
      cellAlign: 'left',
      wrapAround: true,
      pageDots: false,
      prevNextButtons: false
    });

    // custom arrow for next slide
    document.querySelectorAll('.next-slide').forEach(element => {
      element.addEventListener("click", function () {
        testimonialsFlkty.next();
      });
    });

    // equal height cells
    Flickity.prototype._createResizeClass = function () {
      this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function () {
      this.element.classList.remove('flickity-resize');
      resize.call(this);
      this.element.classList.add('flickity-resize');
    };


    /* eslint-enable no-unused-vars */
  }


  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }

  var imageView = document.querySelector('#image-radio')
  var listView = document.querySelector('#list-radio')

  if (imageView) {
    imageView.addEventListener('click', function () {
      const peopleViews = document.querySelectorAll('.people-view');
      peopleViews.forEach(element => {
        element.classList.remove('people-list');
      });
    })
  }

  if (listView) {
    listView.addEventListener('click', function () {
      const peopleViews = document.querySelectorAll('.people-view');
      peopleViews.forEach(element => {
        element.classList.add('people-list');
      });
    })
  }

  const menuToggle = document.querySelectorAll('.menu-toggle');

  for (var i = 0; i < menuToggle.length; i++) {
    menuToggle[i].addEventListener("click", function (event) {
      let dropdownItems = document.getElementsByClassName('dropdown-menu');

      for (let i = dropdownItems.length - 1; i >= 0; i--) {
        if (
          !(dropdownItems[i].previousElementSibling === event.target ||
            dropdownItems[i].previousElementSibling === event.target.parentNode)
        ) {
          if (!dropdownItems[i].classList.contains('hidden')) {
            let el = dropdownItems[i].parentNode.firstChild;
            el.querySelector('.chevron').classList.toggle("rotate-180");
            dropdownItems[i].classList.add('hidden');
          }
        }
      }
      this.querySelector('.chevron').classList.toggle("rotate-180");

      this.nextElementSibling.classList.toggle("hidden");
    })
  }

  const closeDropdown = function (event) {
    if (event.target.classList.contains('menu-toggle') || event.target.parentNode.classList.contains('menu-toggle')) {
      return true;
    }

    let dropdownItems = document.getElementsByClassName('dropdown-menu');

    for (let i = dropdownItems.length - 1; i >= 0; i--) {
      if (!dropdownItems[i].classList.contains('hidden')) {
        let el = dropdownItems[i].parentNode.firstChild;
        el.querySelector('.chevron').classList.toggle("rotate-180");
        dropdownItems[i].classList.add('hidden');
      }
    }
  }
  document.addEventListener('click', closeDropdown);


  const categoryLinks = document.querySelectorAll('.category-link');

  if (categoryLinks) {
    categoryLinks.forEach(link => {
      link.onclick = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }



  //Get the button:
  let backToTop = document.querySelector("#back-to-top")

  if (backToTop) {
    backToTop.addEventListener("click", function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      window.scrollY = 0;
    });
  }


  window.addEventListener("scroll", function () {

    if (document.documentElement.scrollTop > window.innerHeight || document.body.scrollTop > window.innerHeight || window.scrollY > window.innerHeight) {

      backToTop.style.zIndex = 1000;
      backToTop.style.opacity = 1;

    } else if (document.documentElement.scrollTop < window.innerHeight || document.body.scrollTop < window.innerHeight || window.scrollY < window.innerHeight) {
      backToTop.style.zIndex = -1;
      backToTop.style.opacity = 0;
    }

    let stickyHeight = window.innerHeight + document.querySelector("#footer").offsetHeight
    let bottomMargin = 0

    if (document.documentElement.scrollTop > (document.body.clientHeight - stickyHeight) || document.body.scrollTop > (document.body.clientHeight - stickyHeight) || window.scrollY > (document.body.clientHeight - stickyHeight)) {
      bottomMargin = document.documentElement.scrollTop - (document.body.clientHeight - stickyHeight)
      backToTop.style.bottom = `${36 + bottomMargin}px`;
    } else {
      backToTop.style.bottom = `36px`;
    }
  })

  // Add target="_blank" to all external links
  function checkDomain(url) {
    if (url.indexOf('//') === 0) { url = location.protocol + url; }
    return url.toLowerCase().replace(/([a-z])?:\/\//, '$1').split('/')[0];
  };

  function isExternal(url) {
    // verify if link is empty or just 1 char + original answer
    return (url.length > 1 && url.indexOf(':') > -1 || url.indexOf('//') > -1) && checkDomain(location.href) !== checkDomain(url);
  };

  const allLinks = document.querySelectorAll('a');

  allLinks.forEach(link => {
    if (isExternal(link.href)) {
      link.setAttribute('target', '_blank');
    }
  });

  const searchForm = document.querySelector('#search-page-search');
  const searchInput = document.querySelector('#site-navigation-input');

  if (searchInput) {
    searchInput.focus();
  }

  if (searchForm) {
    searchForm.onsubmit = (event) => {
      event.preventDefault();
      if (searchInput) {
        searchInput.blur();
      }
    }
  }
}