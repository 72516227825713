<template>
    <section class="lg:px-2 xl:px-20 pt-16 md:pb-12 max-w-screen m-auto">
      <div class="flex items-center mb-16">
        <span class="mr-6" @click="performSearch()">
          <svg class="w-8 md:w-10 h-8 md:h-10" viewBox="0 0 42 42">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(-82.000000, -698.000000)"
                fill-rule="nonzero"
                stroke="#000000"
                stroke-width="2"
              >
                <g transform="translate(76.000000, 693.000000)">
                  <g transform="translate(30.048179, 29.344931) rotate(-315.000000) translate(-30.048179, -29.344931) translate(4.548179, 13.344931)">
                    <circle cx="15.5903298" cy="15.5903298" r="15.5903298" />
                    <path d="M31.1806597,15.5903298 L49.554977,15.5903298" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
        <form class="w-4/5 search-wrapper" id="people-search" v-on:submit.prevent action="" method="post">
          <input
            v-bind:value="query"
            v-on:input="$emit('update:query', $event.target.value)"
            aria-label="Search"
            class="people-search-input text-xl md:text-5xl w-full tracking-wider md:tracking-normal"
            type="search"
            placeholder="Search for an expert"
          />
          <button
            class="hidden"
            type="submit"
            value="Submit"
            v-on:click="performSearch"
          />
        </form>
      </div>
      <div class="flex flex-wrap -mx-3 xl:-mx-4 items-stretch">
        <template v-for="(categoryGroup, key) in categoryFilters">
          <div class="w-full md:w-1/2 lg:w-1/4 px-3 xl:px-4 mb-8 lg:mb-0" :key="categoryGroup.id + key">
            <div class="relative h-full">
              <div
                class="h-full flex justify-between items-center pb-3 cursor-pointer menu-toggle border-b border-black"
              >
                <span
                  class="inline-block opacity-50 text-xl truncate"
                  :class="{ 'opacity-100' : selectedCategories.find(o => _.get(o, 'parentCategory.id') === categoryGroup.id) }"
                >{{ selectedCategories.find(o => _.get(o, 'parentCategory.id') === categoryGroup.id) ? selectedCategories.find(o => o.parentCategory.id === categoryGroup.id).title : (categoryGroup.handle == 'position') ? 'All our people' : 'Any ' + categoryGroup.groupTitle }}</span>
                <span
                  class="pointer-events-none chevron rotate-180"
                  style="width: 18px; height: 9px;"
                >
                  <svg viewBox="0 0 29 14">
                    <g
                      id="Layout-Desktop"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="practices-single"
                        transform="translate(-1183.000000, -913.000000)"
                        stroke="#000000"
                      >
                        <g id="Group-12-Copy-2" transform="translate(517.000000, 912.000000)">
                          <g id="Path-2" transform="translate(666.922836, 1.500000)">
                            <polyline
                              transform="translate(13.500000, 13.500000) scale(-1, -1) rotate(-315.000000) translate(-13.500000, -13.500000) "
                              points="4 23 23 23 23 4"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <span class="hidden">
                  <svg width="18px" height="16px" viewBox="0 0 18 16">
                    <g
                      id="Layout-Desktop"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="People"
                        transform="translate(-356.000000, -729.000000)"
                        fill-rule="nonzero"
                        stroke="#000000"
                      >
                        <g id="Group-6" transform="translate(80.000000, 719.000000)">
                          <g id="Group-9">
                            <g id="Group-17" transform="translate(269.000000, 2.188077)">
                              <g
                                id="Group-15"
                                transform="translate(16.500000, 16.000000) rotate(-315.000000) translate(-16.500000, -16.000000) translate(5.000000, 5.000000)"
                              >
                                <g id="Group-16" transform="translate(0.000000, -0.000000)">
                                  <path
                                    d="M0.303300859,10.6966991 L22.3033009,10.6966991"
                                    id="Line"
                                  />
                                  <path d="M11,-2.72848411e-11 L11,22" id="Line" />
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <div
                class="absolute w-full pl-6 pr-3 bg-black text-white shadow-md z-50 hidden dropdown-menu overflow-hidden"
                style="left:0; top:0"
              >
              <div class="h-64 overflow-y-scroll category-filter">
                <span
                  :key="key"
                  @click="categorySelector(undefined, categoryGroup);"
                  class="block py-1 hover:opacity-50 transition cursor-pointer text-xl mt-6"
                >{{ (categoryGroup.handle == 'position') ? 'All our people' : 'Any ' + categoryGroup.groupTitle }}</span>
                <template v-for="(row, key) in categoryGroup.categories">
                  <span
                    :key="row.id + key"
                    v-show="!row.children"
                    class="block pt-2 pb-1 hover:opacity-50 transition cursor-pointer text-xl"
                    :class="{'opacity-50': !!_.find(selectedCategories, {'id': row.id}), '': key !== categoryGroup.categories.length - 1, 'opacity-75 mt-2 mb-1': row.children, 'mt-2': key !== 0 && categoryGroup.categories[key - 1].parent && !row.parent, 'mb-6': key === categoryGroup.categories.length - 1 }"
                    @click="!!row && categorySelector(row, categoryGroup);"
                  >{{ row.title }}</span>
                </template>
                </div>
              </div>
              <select
                class="md:hidden appearance-none absolute top-0 right-0 w-full h-full opacity-0 z-10 text-base focus:outline-none focus:bg-white focus:border-white"
                v-model="cat"
                @change="onChange($event)"
              >
                <option
                  :value="{ title: undefined, id: undefined, slug: undefined, parentCategory: { id:  categoryGroup.id } }"
                >{{ (categoryGroup.handle == 'position') ? 'All our people' : 'Any ' + categoryGroup.groupTitle }}</option>
                <template v-for="(row, key) in categoryGroup.categories">
                  <option
                    :key="row.id + key"
                    :selected="!!_.find(selectedCategories, {'id': row.id})"
                    :value="{ title: row.title, id: row.id, slug: row.slug, children: row.children, parentCategory: { id:  categoryGroup.id } }"
                  >{{ row.title }}</option>
                </template>
              </select>
            </div>
          </div>
        </template>
      </div>
    </section>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'CategoryFilter',
  props: {
    query: {
      type: String,
      default: '',
    },
    sectionType: {
      type: Array,
      default: [],
    },
    categoryFilters: {
      type: Array,
      default: [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
  },
  created: function() {
    const self = this
    const catBlocks = _.flatMapDeep(this.categoryFilters)
    _.each(catBlocks, catBlock => {
      if (!!catBlock && this.selectedCategories.includes(catBlock.id)) {
        this.selectedCategories.push(catBlock)
      } else {
        const cat = _.find(catBlock.categories, c => {
          return this.selectedCategories.includes(c.id)
        })
        if (!!cat) {
          let index = _.findIndex(this.selectedCategories, o => o === cat.id)
          this.selectedCategories[index] = {...cat, parentCategory: catBlock}
        }
      }
    })
  },
  mounted: function() {
    const searchForm = document.querySelector('#people-search');
    const searchInput = document.querySelector('.people-search-input');

    if (searchForm) {
      searchForm.onsubmit = (event) => {
        event.preventDefault();
        if (searchInput) {
          searchInput.blur();
        }
      }
    }
  },
  updated: function() {},
  destroyed: function() {},
  data() {
    return {
      isMobileFilter: false,
      cat: {
        id: 0,
        title: '',
        slug: '',
        children: '',
        parentCategory: '',
      },
    }
  },
  computed: {
    _() {
      return _
    },
    moment() {
      return moment
    },
  },
  watch: {
    query: function() {
      this.$emit('update:selected-categories', [])
    },
  },
  methods: {
    resetFilters: function() {
      this.$emit('update:selected-categories', [])
      this.$emit('update:query', '')
      this.$emit('update:start-date', {})
    },
    performSearch: function() {
      this.$emit('search')
    },
    categorySelector: function(cat, groupCat) {
      let newCats = []
      let catObj = {}
      if (!cat) {
        newCats = _.reject(
          this.selectedCategories,
          o => o.parentCategory.id === groupCat.id,
        )
      } else {
        catObj.title = _.get(cat, 'title')
        catObj.slug = _.get(cat, 'slug')
        catObj.id = _.get(cat, 'id')
        catObj.children = _.get(cat, 'children')
        catObj.parentCategory = !!groupCat ? groupCat : undefined
        newCats = _.reject(
          this.selectedCategories,
          o => o.parentCategory.id === catObj.parentCategory.id,
        )
        newCats.push(catObj)
      }
      this.$emit('update:selected-categories', newCats)
    },
    onChange(event) {
      let cat = undefined
      if (this.cat.id) {
        cat = {
          id: this.cat.id,
          slug: this.cat.slug,
          title: this.cat.title,
          children: this.cat.children,
          parentCategory: this.cat.parentCategory,
        }
      }

      this.categorySelector(cat, this.cat.parentCategory)
    },
  },
}
</script>