const grid = document.querySelector(".grid-accordion");
const cell = document.querySelectorAll(".cell");

function scrollToTargetAdjusted(container) {
  var element = container;
  if (document.querySelector('.subnavigation')) {
    var headerOffset = document.querySelector('.subnavigation').offsetHeight
  } else {
    var headerOffset = 0;
  }
  var headerOffset = headerOffset;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
}

if (grid) {

  cell.forEach((current, index) => {

    // get text from each cell's data attribute
    let hasAttribute = false;
    let text = "";
    let title = "";
    let subtitle = "";
    let bgColor = "bg-white";
    if (current.hasAttribute("data-text")) {
      hasAttribute = true;
      text = current.getAttribute("data-text");
    }
    if (current.hasAttribute("data-title")) {
      hasAttribute = true;
      title = current.getAttribute("data-title");
    }
    if (current.hasAttribute("data-subtitle")) {
      hasAttribute = true;
      subtitle = current.getAttribute("data-subtitle");
    }
    if (current.hasAttribute("data-bg")) {
      bgColor = current.getAttribute("data-bg");
    }

    current.addEventListener("click", function () {

      let container;

      if ((window.innerWidth > 1023)) {
        if (index <= 2) {
          container = document.querySelector('.text-container[data-index="1"]');
        } else {
          container = document.querySelector('.text-container[data-index="4"]');
        }
      } else if ((window.innerWidth > 767 && window.innerWidth < 1023)) {
        if (index === 1) {
          container = document.querySelector('.text-container[data-index="0"]');
        } else if (index >= 2) {
          container = document.querySelector('.text-container[data-index="2"]');
        } else if (index >= 4) {
          container = document.querySelector('.text-container[data-index="4"]');
        }
      } else if ((window.innerWidth < 767)) {
        if (index === 1) {
          container = document.querySelector('.text-container[data-index="0"]');
        } else if (index === 2) {
          container = document.querySelector('.text-container[data-index="1"]');
        } else if (index === 3) {
          container = document.querySelector('.text-container[data-index="2"]');
        } else if (index === 4) {
          container = document.querySelector('.text-container[data-index="3"]');
        } else if (index === 5) {
          container = document.querySelector('.text-container[data-index="4"]');
        }

      }

      // check if clicked cell has text
      if ((hasAttribute && text.length)) {
        // Add current cell's text to closest text container and open it
        container.querySelector('.text-container__title').innerHTML = title;
        container.querySelector('.text-container__subtitle').innerHTML = subtitle;
        container.querySelector('.text-container__description').innerHTML = text;
        container.classList.remove('bg-white', 'bg-pink', 'bg-yellow', 'bg-gray-light');
        container.classList.add(bgColor);

        // hide all remaining text containers
        document.querySelectorAll('.text-container').forEach(element => {
          element.classList.add("hidden");
        });


        // check if a cell is active, deactivate if needed
        if (current.classList.contains("is-active")) {
          cell.forEach(element => {
            element.classList.remove("is-active");
          });
          container.classList.add("hidden");
        } else {
          cell.forEach(element => {
            element.classList.remove("is-active");
          });
          current.classList.add("is-active");
          container.classList.remove("hidden");
          scrollToTargetAdjusted(current);
        }

      }
    });

  });
}