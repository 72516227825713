const axios = require('axios').default;
const CancelToken = axios.CancelToken;
let cancel;

// Information needed for connecting to our CraftQL endpoint
export const searchPageApiToken = 'GOpFJ3AUcn1WDwbRRv767Ej9tPWJdVUj';
export const peopleApiToken = '0_pC6b6WldsrYvVtZuM0GLmCP94aHSOa';
export const apiUrl = '/api';

// Configure the api endpoint
export function configureApi(url, token) {
  return {
    baseURL: url,
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
};

// Execute a GraphQL query by sending an XHR to our api endpoint
export function executeQuery(api, query, variables, callback) {
  api.post('', {
    query: query,
    variables: variables
  }, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  }).then((result) => {
    if (callback) {
      callback(result.data);
    }
  }).catch((error) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled: ', error.message);
    } else {
      // handle error
    }
  });
};

export function cancelExecution() {
  if (!_.isUndefined(cancel)) {
    cancel('Operation canceled by the user.');
  }
}

let query = window.location.search.substring(1);
export function getQueryParam(param) {
  if (query.length === 0) {
    return false;
  }
  let params = decodeURIComponent(query).split('&');
  let results = [];
  for (let i = 0; i < params.length; i++) {
    let pair = params[i].split('=');
    if (decodeURIComponent(pair[0]) === param) {
      results.push(decodeURIComponent(pair[1]))
    }
  }
  if (results.length === 0) { results = ''; }
  if (results.length === 1 && param !== 'cat' && param !== 'cat-id') { results = results[0]; }

  return results;
}
