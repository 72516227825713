import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {configureApi, peopleApiToken, apiUrl, getQueryParam, executeQuery} from './searchCommon';

import CategorySearchList from '../../vue/CategorySearchList.vue'
import CategoryFilter from '../../vue/CategoryFilter.vue'

// Site search application
function getcategorySearchLandingData() {
  return {
    sectionType: '',
    searchApi: axios.create(configureApi(apiUrl, peopleApiToken)),
    searchQuery: '',
    searchCategories: [],
    searchResults: [],
    isLoading: true,
    isReady: false
  };
}

let filterChangeTimer;

let searchApp = function() {

// What to search for
const searchSections = JSON.parse(document.getElementById('searchApp').dataset.section);
const searchEntries = JSON.parse(document.getElementById('searchApp').dataset.entries);
const searchTransform = JSON.parse(document.getElementById('searchApp').dataset.transform);
const filterGroups = JSON.parse(document.getElementById('searchApp').dataset.filterGroups);

let searchQueries = ''
_.each(searchEntries, (entryType) => {
  searchQueries = searchQueries +
      `
      ... on people_person_Entry {
        id
        title
        firstName
        lastName
        position {
          title
          parent {
            title
          }
          children {
            id
          }
        }
        jobTitle {
          title
        }
        email
        phoneNumber
        mobileNumber
        url
        image {
          title
          ...on general_Asset {
            imageOptimize {
              src,
              srcset
              placeholderBox
            }
          }
        }
        ${searchTransform.join('\n')}
      }`
});

// The query to search for entries in Craft
const searchQuery =
  `
  query searchQuery($needle: String, $limit: Int, $offset: Int, $orderBy: String, $relatedTo: [QueryArgument])
    {
      entries( section: ["people"] limit: $limit offset: $offset orderBy: $orderBy search: $needle relatedTo: $relatedTo) {
        ${searchQueries}
      }
    }
  `;

  new Vue({
    el: document.getElementById('searchApp'),
    delimiters: ['<%', '%>'],
    // Here we can register any values or collections that hold data
    data: getcategorySearchLandingData(),
    components: {
      CategorySearchList,
      CategoryFilter,
    },
    beforeCreate: function() {
    },
    created: function() {
      const groupParams = _.compact(_.map(filterGroups, group => {
        return getQueryParam(group.handle)
      }));
      const searchParam = getQueryParam('q');
      const scrollToContent = getQueryParam('scrollToContent');

      const allCategories = _.flatten(_.map(filterGroups, 'categories'))
      const categoryIdParam = _.map(groupParams, param => {
        return _.get(_.find(allCategories, o => o.slug === param), 'id')
      })

      if (scrollToContent) {
        const element = document.getElementById('searchApp');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }

      if (!!searchParam) {
        this.searchQuery = searchParam;
      }
      if(!!categoryIdParam) {
        this.searchCategories = categoryIdParam;
      }
      this.sectionType = searchSections;
    },
    mounted: function() {
      this.performSearch();
    },
    updated: function() {
    },
    destroyed: function destroyed() {
    },
    watch: {
      searchQuery: function(val, oldVal) {
        if(!!oldVal && val !== oldVal) {
          this.meta = _.get(getcategorySearchLandingData(), 'meta');
        }
        this.performSearch();
      },
      searchCategories: {
        handler: function(val, oldVal) {
          this.performSearch();
        },
        deep: true
      },
      searchSorting: function() {
        this.performSearch();
      },
    },
    filters: {
    },
    computed: {
    },
    methods: {
      performSearch() {
        let self = this;

        self.isLoading = true;
        self.searchResults = [];
        self.setHistory();

        let searchTitle = !!self.searchQuery ? `firstName:${self.searchQuery} OR lastName:${self.searchQuery} OR title:${self.searchQuery} OR email:${self.searchQuery}` : '';

        let searchCategoriesArray = [];
        if (!_.isEmpty(self.searchCategories)) {
          _.map(self.searchCategories, o => {
            searchCategoriesArray.push(o.id)
          })
        }

        // Set the variables we will pass in to our query
        let variables = {
            sections: searchSections,
            orderBy: 'score',
            // orderBy: (!!self.searchQuery) ? 'score' : 'CONVERT(field_lastName USING utf8) COLLATE utf8_bin',
            needle: _.compact([searchTitle]).join(' '),
            relatedTo: !_.isEmpty(searchCategoriesArray) ? ['and', ...searchCategoriesArray] : []
        };

        // Execute the query
        clearTimeout(filterChangeTimer);

        filterChangeTimer = setTimeout(function() {
          executeQuery(self.searchApi, searchQuery, variables, (data) => {
            self.searchResults = data.data.entries;

            if(!self.isReady) {
              self.isReady = true;
            }
            self.isLoading = false;
          });
        }, 500);
      },
      setHistory: function() {
        let self = this;
        let paramString = '';
        if(!!self.searchQuery) {
          paramString += '?q=' + self.searchQuery;
        }
        if(!!_.get(self.searchCategories[0], 'id')) {
          _.forEach(self.searchCategories, o => {
            paramString += !!paramString ? (`&${o.parentCategory.handle}=` + o.slug) : (paramString += `?${o.parentCategory.handle}=` + o.slug);
          })
        }
        if (window.history && window.history.replaceState) {
          let pageUrl =
            location.protocol + '//' + location.host + location.pathname;
          let url = pageUrl + paramString;
          history.replaceState(null, null, url);
        }
      },
      scrollup: function() {
        let top = 0;

        setTimeout(function() {
          window.scrollTo(top, 0);
        }, 100);
        return false;
      },
    },
  });
};

!!document.getElementById('searchApp') && searchApp();
