var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fadeIn", mode: "in-out" } }, [
    _c(
      "div",
      {
        staticClass:
          "md:px-3 xl:px-4 w-full md:w-1/2 lg:w-1/4 mb-6 md:mb-8 overflow-hidden people-card-outer text-base"
      },
      [
        _c("a", { staticClass: "block", attrs: { href: _vm.item.url } }, [
          _c(
            "figure",
            {
              staticClass:
                "aspect-ratio-70 relative mb-6 bg-gray-image object-fit-fallback"
            },
            [
              !!_vm.item.image && !!_vm.item.image[0]
                ? [
                    _c("img", {
                      staticClass:
                        "lazyload absolute top-0 left-0 w-full h-full block object-cover",
                      attrs: {
                        src: "",
                        "data-srcset": _vm.item.image[0].imageOptimize.srcset,
                        alt: _vm.item.image[0].title
                          ? _vm.item.image[0].title
                          : _vm.item.title,
                        "data-sizes": "auto"
                      }
                    })
                  ]
                : [
                    _c("img", {
                      staticClass:
                        "lazyload absolute top-0 left-0 w-full h-full block object-cover",
                      attrs: {
                        src: "/assets/img/person-placeholder.jpg",
                        srcset: "",
                        alt: _vm.item.title
                      }
                    })
                  ]
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-1 md:mb-2" }, [
          _c(
            "a",
            {
              staticClass:
                "border-b border-transparent hover:border-black transition",
              attrs: { href: _vm.item.url }
            },
            [_vm._v(_vm._s(_vm.item.title))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2 md:mb-4" }, [
          _vm._v(
            _vm._s(
              _vm.item.jobTitle.length > 0 ? _vm.item.jobTitle[0].title : ""
            )
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-1 md:mb-2 ellipsis" }, [
          _c(
            "a",
            {
              staticClass:
                "border-b border-transparent hover:border-black transition",
              attrs: { href: "mailto:" + _vm.item.email }
            },
            [_vm._v(_vm._s(_vm.item.email))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-2" }, [
          _c(
            "a",
            {
              staticClass:
                "border-b border-transparent hover:border-black transition",
              attrs: { href: "tel:" + _vm.item.mobileNumber }
            },
            [_vm._v(_vm._s(_vm.item.mobileNumber))]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }